<template>
  <div class="standalone-hosting-request-info">
    <h1 class="m-0 p-0"> {{ headerText }} </h1>
    <div v-if="standaloneHostingVerified" class="pt-2">
      <p class="mb-0">We have completed your standalone hosting request.</p>
    </div>
    <div v-else-if="pendingHostingInfo.requestCall" class="pt-2">
      <p class="mb-0">We have received your request for a call to help set up your hosting.</p>
      <p class="mb-0">One of our hosting experts will be in touch with you shortly.</p>
    </div>
    <div v-else class="pt-2">
      <p class="mb-0 pb-2">
        Next, your hosting is being provisioned.
      </p>
      <ul>
        <li>You will receive a notification once the request process is complete</li>
        <li>If any problems arise, we'll reach out to you regarding the next steps</li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  name: 'StandaloneHostingStatus',
  props: {
    pendingHostingInfo: {
      type: Object,
      required: true,
    },
    bus: {
      type: Object,
      required: true,
    },
  },
  computed: {
    standaloneHostingVerified() {
      return this.pendingHostingInfo.status === "completed-standalone-hosting"
    },
    headerText() {
      return this.standaloneHostingVerified ? "Use Your Own Domain process complete" :
        `Use Your Own Domain request submitted for ${this.pendingHostingInfo.domainName}`
    },
  },
}
</script>

