var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "standalone-hosting-request-info" }, [
    _c("h1", { staticClass: "m-0 p-0" }, [
      _vm._v(" " + _vm._s(_vm.headerText) + " "),
    ]),
    _vm.standaloneHostingVerified
      ? _c("div", { staticClass: "pt-2" }, [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v("We have completed your standalone hosting request."),
          ]),
        ])
      : _vm.pendingHostingInfo.requestCall
      ? _c("div", { staticClass: "pt-2" }, [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(
              "We have received your request for a call to help set up your hosting."
            ),
          ]),
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(
              "One of our hosting experts will be in touch with you shortly."
            ),
          ]),
        ])
      : _c("div", { staticClass: "pt-2" }, [
          _c("p", { staticClass: "mb-0 pb-2" }, [
            _vm._v("\n      Next, your hosting is being provisioned.\n    "),
          ]),
          _vm._m(0),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "You will receive a notification once the request process is complete"
        ),
      ]),
      _c("li", [
        _vm._v(
          "If any problems arise, we'll reach out to you regarding the next steps"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }